

export async function loadEvents(start, end)
{
    console.log("Loading Range: ", start, end);
    let url = 'https://russell.tiedemann.co.nz/api.php?a=g';
    if (start) url += '&s=' + start;
    if (end) url += '&e=' + end;
    return await fetch(url).then(res => res.json());
}

export async function setEvent(id, title, start, end, password)
{
    console.log("Saving Event: ", title, start, end);
    if (end - start > 86400 * 7)
    {
        if (!window.confirm("Are you sure you want to book more than 7 days?")) return "Canceled";
    }
    let url = 'https://russell.tiedemann.co.nz/api.php?a=p';
    const payload = {title, start, end, id: id ?? 0, password: password ?? ''};
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    }).then(res => res.json());
}

export async function deleteEvent(id, password)
{
    console.log("Deleting Event: ", id);
    let url = 'https://russell.tiedemann.co.nz/api.php?&a=d';
    const payload = {id, password};
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    }).then(res => res.json());
}

window.debug = {
    loadEvents,
    setEvent
};