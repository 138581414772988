import React, {Fragment, useState, useCallback, useMemo, useEffect} from 'react'
import {Calendar, Views, DateLocalizer, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {deleteEvent, loadEvents, setEvent} from "./API";
import {AddModal} from "./AddModal";
import {EditModal} from "./EditModal";
import {PasswordModal} from "./PasswordModal";

export function MyCalendar({showModal, setShowModal}) {
    const [myEvents, setEvents] = useState([]);
    const [currentRange, setRange] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [lastUpdate, setLastUpdate] = useState(0);
    const [selectedEvent, _setSelectedEvent] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordRequired, setPasswordRequired] = useState(false);

    const localizer = momentLocalizer(moment);

    useEffect(() => {
        (async function() {
            setLoading(true);
            const start = currentRange?.start.getTime() / 1000;
            const end = currentRange?.end.getTime() / 1000;
            const events = await loadEvents(start, end);
            if (events)
            {
                setEvents(events.map(event => {
                    return {
                        id: event.id,
                        title: event.name,
                        allDay: true,
                        start: new Date(event.start * 1000),
                        end: new Date(event.end * 1000),
                    };
                }));
            }
            else setEvents([]);
            setLoading(false);
        })();
    }, [currentRange, lastUpdate]);

    const setSelectedEvent = (event) => {
        setPasswordRequired(true);
        _setSelectedEvent(event);
    };

    const handleSelectSlot = async (slot) => {
        console.log("Slot Selected: ", slot);
        const title = prompt("Your Name: ");
        if (!title) return;
        const start = slot.start.getTime() / 1000;
        const end = slot.end.getTime() / 1000;

        const event = await setEvent(0, title, start, end);
        if (event === 'success') alert("Success!");
        else alert("Failed: " + event);
        setLastUpdate(Date.now());
    };

    return (
        <Fragment>
            <div style={{height: 600}}>
                <Calendar
                    defaultView={Views.MONTH}
                    events={myEvents}
                    localizer={localizer}
                    views={{month: true}}
                    onRangeChange={setRange}
                    onSelectEvent={event => setSelectedEvent(event)}
                />
            </div>
            {isLoading ? <div className="loading-overlay">Loading...</div> : ""}
            <AddModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onSave={async (title, start, end) => {
                    console.log("Save Event Form: ", title, start, end);
                    const event = await setEvent(0, title, start, end);
                    if (event === 'success')
                    {
                        alert("Success!");
                        setShowModal(false);
                    }
                    else alert("Failed: " + event);
                    setLastUpdate(Date.now());
                }}
            />
            {selectedEvent && password ? <EditModal
                show={!!selectedEvent}
                selectedEvent={selectedEvent}
                onClose={() => setSelectedEvent(false)}
                onDelete={async (eid) => {
                    await deleteEvent(eid, password);
                    setSelectedEvent(false);
                    setLastUpdate(Date.now());
                }}
                onSave={async (id, title, start, end) => {
                    const event = await setEvent(id, title, start, end, password);
                    if (event === 'success')
                    {
                        alert("Success!");
                        setSelectedEvent(false);
                    }
                    else alert("Failed: " + event);
                    setLastUpdate(Date.now());
                }}
            /> : ""}
            {passwordRequired ? <PasswordModal
                show={!!passwordRequired && !password}
                defaultPassword={password}
                onClose={() => setPasswordRequired(false)}
                onSave={(password) => {
                    setPassword(password);
                    setPasswordRequired(false);
                }}
            /> : ""}
        </Fragment>
    )
}