import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Form} from "react-bootstrap";

export function AddModal({show, onClose, onSave}) {
    const [title, setTitle] = useState('');
    const [startDate, _setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isError, setError] = useState(false);
    const [days, setDays] = useState(0);

    const setStartDate = (value) => {
        _setStartDate(value);
        setEndDate(value);
    }
    const unix = (ms, plus=0) => {
        let s = Math.floor(ms / 1000);
        s = Math.floor(s / 86400) * 86400;
        if (plus) s += plus;
        return s;
    }
    const saveDate = () => {
        let start = new Date(startDate);
        let end = new Date(endDate);
        onSave(title, unix(start.getTime()), unix(end.getTime() - 1, 86399));
    };

    useEffect(() => {

        let error = false;
        if (!title) error = 'Please enter a name';
        else if (!startDate) error = 'Please enter a start date.';
        else if (!endDate) error = 'Please end an end date.';
        else if (startDate > endDate) error = 'Start Date must be before End Date.';
        setError(error);

        let new_days = 0;
        if (startDate && endDate)
        {
            const start = unix((new Date(startDate)).getTime());
            const end = unix((new Date(endDate)).getTime() - 1, 86399);
            new_days = Math.round((end - start) / 86400);
        }
        setDays(new_days)
    }, [title, startDate, endDate]);

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Book a Date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <p style={{color: 'darkblue'}}>{isError}</p>
                    <Form.Group className="mb-3" controlId="bookadate.name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name..." value={title} onChange={e => setTitle(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="bookadate.from">
                        <Form.Label>From</Form.Label>
                        <Form.Control
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="bookadate.until">
                        <Form.Label>Until</Form.Label>
                        <Form.Control
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </Form.Group>
                    {days ? <p>You have entered {days} days</p> : ""}
                    <h6 style={{color: 'darkblue'}}>Please only make a reservation if you are committed to go.<br/>If you wish to cancel, please let Peter know.</h6>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={saveDate} disabled={isError}>
                    Save the Date
                </Button>
            </Modal.Footer>
        </Modal>
    );
}