import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {MyCalendar} from "./MyCalendar";
import {useState} from "react";
import Button from "react-bootstrap/Button";

function App() {
    const [showModal, setShowModal] = useState(false);
      return (
        <div className="App">
            <div style={{marginBottom: 10}}>
                <h5 style={{color: "white"}}>Russell Calendar</h5>
                <p style={{color: "white", fontSize: "small"}}>Administrator: pect@xtra.co.nz</p>
                <Button variant="primary" onClick={() => setShowModal(true)}>Book Dates</Button>
            </div>
            <div style={{height: 600, width: '100%', backgroundColor: 'white'}}>
                <MyCalendar
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            </div>
        </div>
      );
}

export default App;
